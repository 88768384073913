import { useMediaQuery } from '@material-ui/core';
import { CashHoldingCard } from 'components/HoldingCard/CashHoldingCard';
import { HoldingCard } from 'components/HoldingCard/HoldingCard';
import { ResidualAssetsCard } from 'components/HoldingCard/ResidualAssetsCard';
import { QueryState } from 'components/QueryState';
import {
  AccountsQuery,
  useAccountsQuery,
  useRecurringTransactionsQuery,
  WrapperType,
} from 'generated/graphql';
import { filterPositions } from 'helpers/filterPositions';
import { getHoldingName } from 'helpers/instrumentNaming';
import { isDarkUniversePosition, sortPositions } from 'helpers/positionHelpers';
import { useIsAccountClosing } from 'hooks/useAccountStatus';
import { useTheme } from 'styled-components';
import { RecurringTransactionsQueryRecurringTransactions } from 'types/graphqlTypes';
import { SupportedWrapperType } from 'types/utilityTypes';
import { HoldingsContainer } from '../Styles/Holdings.styles';

type Position = Exclude<
  AccountsQuery['accounts'],
  undefined | null
>[number]['positions'][number];

interface PureNewHoldingsProps {
  positions: Array<Position>;
  accountId: string;
  accountType: WrapperType;
  closingAccount?: boolean;
  pendingOrders: number;
  availableAmount: number;
  recurringTransactions?: null | RecurringTransactionsQueryRecurringTransactions;
}

export function PureNewHoldings({
  positions,
  accountId,
  accountType,
  closingAccount = false,
  pendingOrders,
  availableAmount,
  recurringTransactions,
}: PureNewHoldingsProps) {
  const [inUniversePositions, residualAssets] = filterPositions(positions);
  const theme = useTheme();
  const atLeastTablet = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const atLeastDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const sorted = sortPositions(inUniversePositions);

  const residualAssetsAmount = residualAssets.reduce(
    (acc, asset) => acc + asset.currentValue,
    0
  );

  const instrumentsHeld = inUniversePositions.map((p) => p.instrument!);

  const latestEmployerContribution =
    recurringTransactions?.latestEmployerContribution?.amount ?? 0;

  const totalRecurringDepositAmount =
    recurringTransactions?.deposits?.reduce<number>(
      (total, selectedRecurringDeposit) => {
        if (selectedRecurringDeposit.autoInvest) {
          return total + selectedRecurringDeposit.amount;
        }
        return total;
      },
      0
    ) ?? 0;

  const totalAutoInvestDepositAmount =
    totalRecurringDepositAmount + latestEmployerContribution;

  return (
    <HoldingsContainer>
      {sorted.map((position) => {
        const recurringTransactionOrder = position.isPendingRecurringOrderInstruction
          ? recurringTransactions?.orders?.find(
              (order) => order.isin === position.isin
            )
          : undefined;

        const recurringTransactionOrderAmount =
          recurringTransactionOrder && recurringTransactionOrder.proportion
            ? {
                ...recurringTransactionOrder,
                amount:
                  totalAutoInvestDepositAmount *
                  recurringTransactionOrder.proportion,
              }
            : undefined;

        return (
          <HoldingCard
            key={position.isin}
            position={position}
            accountId={accountId}
            isClosing={closingAccount}
            displayAssetName={getHoldingName(
              position.instrument!,
              instrumentsHeld
            )}
            accountType={accountType}
            hasMeetTheManagerVideo={
              position.instrument?.asset?.meetManagerVideo !== null
            }
            atLeastTablet={atLeastTablet}
            atLeastDesktop={atLeastDesktop}
            recurringTransactionsOrder={recurringTransactionOrderAmount}
            isDarkUniversePosition={isDarkUniversePosition(position)}
          />
        );
      })}
      {residualAssetsAmount > 0 && (
        <ResidualAssetsCard amount={residualAssetsAmount} />
      )}
      <CashHoldingCard
        accountId={accountId}
        accountType={accountType}
        closingAccount={closingAccount}
        pendingOrders={pendingOrders}
        availableAmount={availableAmount}
      />
    </HoldingsContainer>
  );
}

interface NewHoldingsProps {
  accountType: SupportedWrapperType;
}

export function NewHoldings({ accountType }: NewHoldingsProps) {
  const accountsQuery = useAccountsQuery();
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === accountType
  );

  const availableCash = currentAccount?.valuationSummary?.uninvestedCash ?? 0;
  const isAccountClosing = useIsAccountClosing(currentAccount?.wrapperType!);
  const pendingOrders = currentAccount?.pendingBuyValue ?? 0;

  const recurringTransactionsQuery = useRecurringTransactionsQuery({
    accountId: currentAccount?.id as string,
  });
  const recurringTransactions =
    recurringTransactionsQuery.data?.recurringTransactions;

  return (
    <QueryState {...recurringTransactionsQuery}>
      {() => (
        <PureNewHoldings
          positions={currentAccount?.positions!}
          accountId={currentAccount?.id!}
          accountType={currentAccount?.wrapperType!}
          closingAccount={isAccountClosing}
          pendingOrders={pendingOrders}
          availableAmount={availableCash}
          recurringTransactions={recurringTransactions}
        />
      )}
    </QueryState>
  );
}
