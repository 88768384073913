import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { H6, assetClasses } from 'components/design-system/Heading/Heading';
import { Pill } from 'components/design-system/Pill/Pill';
import {
  FontSize,
  TextNormal,
  TextXS,
} from 'components/design-system/Text/Text';
import { MinTradeUnitStatus } from 'components/feature/autoSaveInvest/regularInvest/_shared/MinTradeUnitStatus';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa, trackGaClearEcommerce } from 'helpers/track';
import { amount } from 'helpers/yupExtensions';
import { generateFundDetailsPath } from 'paths';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Reference from 'yup/lib/Reference';
import * as format from '../../../../../formatting';
import { BasketBuyOrder, useFundsBasket } from '../../hooks/useFundsBasket';
import {
  AmountInput,
  AssetClassPill,
  DetailsContainer,
  ErrorWrapper,
  FundBasketOrderLayout,
  MobileOrder,
  MobileOrderDetailsRow,
  MobileOrderInputWrapper,
  MobileOrderName,
  MobileOrderNameRow,
  NameContainer,
  OpenInNew,
  RemoveContainer,
} from '../_shared/FundsBasketShared.styles';
import { RemoveButton, StyledCard } from './fundBasketBuyOrder.styles';

const parseFloatStringOrNumber = (value: string | number) => {
  return typeof value === 'string' ? parseFloat(value) : value;
};

interface FundBasketBuyOrderProps {
  buyOrder: BasketBuyOrder;
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
}

/**
 * FundBasketBuyOrder component
 */
export function FundBasketBuyOrder({
  buyOrder,
  selectedAccountId,
  selectedAccountType,
}: FundBasketBuyOrderProps) {
  const [, setMode] = useMode();

  const isMutatingRebalancing = useIsMutating(['UpdatePortfolioRebalancing']);

  const { updateBuyOrder, getBuyOrder, remove } = useFundsBasket({
    selectedAccountId,
  });

  const minAmount = buyOrder.minAmount;
  const units = buyOrder.units;

  const maxUnauthAmount = 1000000;
  const hasSelectedAccountId = !!selectedAccountId;

  const theme = useTheme();
  const isMbUp = useMediaQuery(theme.breakpoints.up('sm'));

  const amountFormSchema = yup.object().shape({
    amount: amount()
      .label('Amount')
      .min(
        yup.ref('$minAmount') as Reference<number>,
        `Please type in a value bigger than ${format.currencyFull(minAmount!)}`
      )
      .required('Please type in a valid amount')
      .typeError('Please type in a valid amount')
      .when('$hasSelectedAccountId', {
        is: false,
        then: yup
          .number()
          .typeError('Please enter a valid amount')
          .max(
            yup.ref('$maxUnauthAmount') as Reference<number>,
            `Please type in a value no bigger than ${format.currencyFull(
              maxUnauthAmount!
            )}`
          ),
      }),
  });

  const { register, handleSubmit, setValue, errors, trigger, watch } = useForm({
    resolver: yupResolver(amountFormSchema),
    defaultValues: {
      amount: buyOrder.amount,
    },
    mode: 'onBlur',
    context: { minAmount, maxUnauthAmount, hasSelectedAccountId },
  });

  const watchAmount = parseFloatStringOrNumber(watch('amount'));

  const handleChange = async () => {
    await trigger();
  };

  useEffect(() => {
    setValue('amount', buyOrder.amount);
  }, [buyOrder.amount, setValue]);

  const assetClassName = buyOrder.assetClass as assetClasses;

  const onSubmit = (data: any) => {
    const existingBuyOrder = getBuyOrder(buyOrder?.id || 0);
    if (!existingBuyOrder) {
      return;
    }
    const updatedBuyOrder = {
      ...existingBuyOrder,
      units,
      amount: parseFloat(data.amount || 0),
    };

    updateBuyOrder(updatedBuyOrder);
    trackGa({
      event: GaEventNames.updateCart,
      orderType: 'rebalancing',
      ecommerce: {
        items: [
          {
            currency: 'GBP',
            item_id: buyOrder.id,
            item_name: buyOrder.fundName,
            item_type: buyOrder.assetClass,
            affiliation: 'Tillit',
            item_variant: selectedAccountType,
            item_list_name: 'DPC Basket buys',
            price: buyOrder.amount,
            quantity: Math.round(units * 100) / 100,
          },
        ],
      },
    });
  };

  // Remove item from basket
  const handleRemoveItem = async () => {
    if (isMutatingRebalancing <= 0) {
      trackGaClearEcommerce();
      trackGa({
        event: GaEventNames.removeFromCart,
        orderType: 'rebalancing',
        ecommerce: {
          items: [
            {
              currency: 'GBP',
              item_id: buyOrder.id,
              item_name: buyOrder.fundName,
              item_type: buyOrder.assetClass,
              affiliation: 'Tillit',
              item_variant: selectedAccountType,
              item_list_name: 'DPC Basket buys',
              price: buyOrder.amount,
              quantity: Math.round(units * 100) / 100,
            },
          ],
        },
      });

      const { accountBasket } = await remove(buyOrder.isin);

      if (
        accountBasket.basketBuyOrders.length === 0 &&
        accountBasket.basketSellOrders.length === 0
      ) {
        setMode(null);
      }
    }
  };

  return isMbUp ? (
    <StyledCard $isDarkUniverse={buyOrder.isDarkUniverse}>
      <FundBasketOrderLayout>
        <NameContainer>
          <Link
            to={generateFundDetailsPath({
              id: buyOrder.id,
              slug: buyOrder.slug!,
            })}
          >
            <H6
              darkUniverse={buyOrder?.isDarkUniverse}
              $noMargin={!buyOrder?.isDarkUniverse}
            >
              {buyOrder.instrumentName}{' '}
              <OpenInNew $isDarkUniverse={buyOrder?.isDarkUniverse} />
            </H6>
          </Link>
          {buyOrder.askPrice && (
            <MinTradeUnitStatus
              variant="popover"
              amount={watchAmount}
              askPrice={buyOrder.askPrice}
              minimumTradeUnit={buyOrder.minimumTradeUnit}
              wrapperType={selectedAccountType}
              orderType="one-off"
            />
          )}
          {assetClassName && (
            <div style={{ marginTop: '0.5rem' }}>
              <Pill $fontSize={FontSize.small}>{assetClassName}</Pill>
            </div>
          )}
        </NameContainer>

        <RemoveContainer>
          <RemoveButton
            onClick={() => handleRemoveItem()}
            $isActive={isMutatingRebalancing <= 0}
            $isDarkUniverse={buyOrder?.isDarkUniverse}
          >
            <AiOutlineClose />
          </RemoveButton>
        </RemoveContainer>

        <DetailsContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AmountInput
              id="amount"
              name="amount"
              placeholder="Amount"
              readOnly={isMutatingRebalancing > 0}
              required
              onBlur={handleSubmit(onSubmit)}
              onChange={handleChange}
              ref={register}
              $error={!!errors.amount}
            />
          </form>
        </DetailsContainer>
      </FundBasketOrderLayout>
      {!!errors.amount && (
        <ErrorWrapper>
          <TextXS $noMargin>{errors.amount.message}</TextXS>
        </ErrorWrapper>
      )}
    </StyledCard>
  ) : (
    <MobileOrder $isDarkUniverse={buyOrder?.isDarkUniverse ?? false}>
      <MobileOrderNameRow>
        <Link
          to={generateFundDetailsPath({
            id: buyOrder.id!,
            slug: buyOrder.slug!,
          })}
        >
          <MobileOrderName $isDarkUniverse={buyOrder?.isDarkUniverse}>
            {buyOrder.instrumentName}{' '}
            <OpenInNew $isDarkUniverse={buyOrder?.isDarkUniverse} />
          </MobileOrderName>
        </Link>
        <RemoveButton
          onClick={() => handleRemoveItem()}
          $isActive={isMutatingRebalancing <= 0}
        >
          <AiOutlineClose />
        </RemoveButton>
      </MobileOrderNameRow>

      <MobileOrderDetailsRow>
        {assetClassName && (
          <AssetClassPill $assetClass={assetClassName}>
            <TextNormal>{assetClassName}</TextNormal>
          </AssetClassPill>
        )}
        <MobileOrderInputWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AmountInput
              id="amount"
              name="amount"
              placeholder="Amount"
              readOnly={isMutatingRebalancing > 0}
              required
              onBlur={handleSubmit(onSubmit)}
              onChange={handleChange}
              ref={register}
              $error={!!errors.amount}
            />
          </form>
        </MobileOrderInputWrapper>
      </MobileOrderDetailsRow>
      {!!errors.amount && (
        <ErrorWrapper>
          <TextXS $noMargin>{errors.amount.message}</TextXS>
        </ErrorWrapper>
      )}
    </MobileOrder>
  );
}
