import { deriveMinTradeUnitStatus } from 'components/Dialogs/Trading/helpers';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { StyledA } from 'components/design-system/Link';
import {
  FontSize,
  Text,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { currencyFull } from 'formatting';
import { WrapperType } from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import 'numeral/locales/en-gb';
import { faqLeaveCashUninvested } from 'paths/externalPaths';
import { transparentize } from 'polished';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styled from 'styled-components';

const MinTradeUnitStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${Text} {
    color: ${colors.danger} !important;
  }
  ${Text} > ${StyledA} {
    color: ${colors.danger} !important;
    ::before {
      background-color: ${transparentize(0.75, colors.danger)} !important;
    }
    ::after {
      background-color: ${colors.danger} !important;
    }
  }
`;

interface MinTradeUnitStatusDetailsProps {
  summary: React.ReactNode;
  details: React.ReactNode;
  fontSize: FontSize;
}

export const MinTradeUnitStatusDetailsPopover = ({
  summary,
  details,
  fontSize = FontSize.normal,
}: MinTradeUnitStatusDetailsProps) => {
  return (
    <Text $noMargin $fontSize={fontSize}>
      {summary}
      <InfoPopoverV2
        color="secondary"
        $width="wide"
        placement={'top'}
        size="small"
        content={<TextSmall>{details}</TextSmall>}
      />
    </Text>
  );
};

export const MinTradeUnitStatusDetailsDisplay = ({
  summary,
  details,
}: MinTradeUnitStatusDetailsProps) => {
  return (
    <>
      <TextNormal $noMargin>{summary}</TextNormal>
      <TextSmall>{details}</TextSmall>
    </>
  );
};

interface MinTradeUnitStatusProps {
  wrapperType: WrapperType;
  minimumTradeUnit: number;
  askPrice: number;
  amount: number;
  variant?: 'popover' | 'display';
  fontSize?: FontSize;
  orderType: 'one-off' | 'recurring';
}

export const MinTradeUnitStatus = ({
  wrapperType,
  amount,
  askPrice,
  minimumTradeUnit,
  variant = 'display',
  fontSize = FontSize.normal,
  orderType,
}: MinTradeUnitStatusProps) => {
  const [showMinTradeUnitStatus] = useToggle(
    'global-show-min-trade-unit-status'
  );

  const minTradeUnitStatus = deriveMinTradeUnitStatus({
    tradeAmount: amount,
    askPrice,
    minTradeableUnit: minimumTradeUnit,
  });

  if (!showMinTradeUnitStatus || amount === 0) {
    return null;
  }

  const MinTradeUnitStatusDetails =
    variant === 'display'
      ? MinTradeUnitStatusDetailsDisplay
      : MinTradeUnitStatusDetailsPopover;

  return (
    <MinTradeUnitStatusWrapper>
      {minTradeUnitStatus?.belowSuggestedAmount && (
        <MinTradeUnitStatusDetails
          fontSize={fontSize}
          summary={
            <>
              <strong>Please note</strong> - some of this cash could be left
              uninvested.
            </>
          }
          details={
            <>
              This fund's latest price per unit/share is{' '}
              {currencyFull(askPrice)} and will fluctuate over time. Since it is
              traded in multiples of {minimumTradeUnit} units/shares this order
              might secure a much smaller value than the {currencyFull(amount)}{' '}
              you entered. This would leave some or all of your cash uninvested.{' '}
              <StyledA href={faqLeaveCashUninvested} target="_blank">
                Learn more <FaExternalLinkAlt />
              </StyledA>
              .
            </>
          }
        />
      )}
    </MinTradeUnitStatusWrapper>
  );
};
