import { CookieConsent, CookiePreferences } from '@tillitinvest/cookie-consent';
import GlobalStyle from 'App.style';
import { AuthRoute } from 'components/AuthRoute/AuthRoute';
import { CashDepositDeepLink } from 'components/CashDepositDeepLink';
import { Layout } from 'components/Layout';
import { LoadingScreen } from 'components/LoadingScreen';
import { QueryState } from 'components/QueryState';
import { SVGColorFilter } from 'components/SVGColorFilter';
import { ToastType } from 'components/Toast/Toast';
import { FundsBasketProvider } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { ResumeOnboardingNudge } from 'components/feature/ResumeOnboarding/ResumeOnboardingNudge';
import { SelectFundsPercentagesStepContextProvider } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import {
  AuthInvitationRedirect,
  UnAuthInvitationRedirect,
} from 'components/feature/invitation/InvitationRedirect';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { ToastProvider, useToast } from 'context/ToastContext';
import { WrapperType, useUserProfileQuery } from 'generated/graphql';
import { setupGtag } from 'helpers/setupGtag';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import mixpanel from 'mixpanel-browser';
import ModalProvider from 'mui-modal-provider';
import { AutoSaveInvestPage } from 'pages/AutoSaveInvest/AutoSaveInvest';
import { DynamicPortfolioConstructionPage } from 'pages/DynamicPortfolioConstruction/DynamicPortfolioConstruction';
import { FundDetails } from 'pages/FundDetails/FundDetails';
import { RedirectFundDetails } from 'pages/FundDetails/RedirectFundDetails';
import { Landing } from 'pages/Landing/Landing';
import { MyDashboard } from 'pages/MyDashboard/MyDashboard';
import { NotFound } from 'pages/NotFound/NotFound';
import { OpenAccount } from 'pages/OpenAccount/OpenAccount';
import { OpenAccountPreAuth } from 'pages/OpenAccount/OpenAccountPreAuth';
import { OpenSippAsSecondAccount } from 'pages/OpenSippAsSecondAccount/OpenSippAsSecondAccount';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword';
import { EnableMfa } from 'pages/SetupMfa';
import { SignIn } from 'pages/SignIn/SignIn';
import { SignInSignUp } from 'pages/SignInSignUp/SignInSignUp';
import { SignedOut } from 'pages/SignedOut/SignedOut';
import { StartTransferPage } from 'pages/StartTransfer/StartTransfer';
import { UploadDocumentToRequest } from 'pages/UploadDocumentToRequest/UploadDocumentToRequest';
import {
  appResetPasswordPath,
  appSignInPath,
  appSignInSignUpPath,
  autoSaveInvestPath,
  checkoutPath,
  dashboardAccountAddCashPath,
  dashboardPath,
  dashboardSubPath,
  dynamicPortfolioConstructionBasePath,
  dynamicPortfolioConstructionFundsAddCashPath,
  dynamicPortfolioConstructionPath,
  fundDetailsOldPath,
  fundDetailsPath,
  fundDetailsSubPath,
  fundListPath,
  hexagonPagePath,
  invitationPath,
  openAccountPath,
  openAccountResumeAccountPath,
  openAccountResumeTypePath,
  openAccountTypePath,
  openPensionPath,
  startTransferPath,
  xrayBetaPath,
} from 'paths';
import React, { Suspense, useEffect, useState } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useEffectOnce, useSessionStorage } from 'usehooks-ts';

const Funds = React.lazy(() => import('pages/Funds'));

const XrayBetaPage = React.lazy(() => import('pages/XrayBetaPage'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    /*
      TODO: find a better way of handling page positions so that the default action
      is to scroll back to the top but to allow to change that behavior when required a different action
    */
    if (
      !pathname.startsWith('/dashboard/') &&
      !pathname.startsWith(dynamicPortfolioConstructionBasePath) &&
      !pathname.startsWith('/funds/')
    ) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
type Location = ReturnType<typeof useLocation>;

const fullUrlFromLocation = (location: Location) =>
  `${window.location.origin}${location?.pathname}${location?.search}`;

const usePageViews = () => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<Location>();
  useEffect(() => {
    const sendPageView = () => {
      if (location && previousLocation) {
        const prevURL = fullUrlFromLocation(previousLocation);
        const currentURL = fullUrlFromLocation(location);
        if (prevURL !== currentURL)
          trackGa({
            event: GaEventNames.virtualPageview,
            prevURL,
            currentURL,
            pageTitle: window.document.title,
          });
      }
      setPreviousLocation(location);
    };
    setTimeout(sendPageView, 64);
  }, [location, previousLocation]);
};

const UrlTriggeredChatPopper = () => {
  const location = useLocation();
  const history = useHistory();
  const { showNewMessages } = useIntercom();

  useEffect(() => {
    const paramName = 'openChat';

    const query = new URLSearchParams(location.search);

    if (query.has(paramName)) {
      showNewMessages();

      query.delete(paramName);

      history.replace({
        search: query.toString(),
      });
    }
  }, [location, history, showNewMessages]);

  return null;
};

const SignedOutToaster = () => {
  const location = useLocation();
  const history = useHistory();
  const { openToast } = useToast();
  const { signedIn } = useAuth();
  const [lastLoginTimestampIsoString] = useSessionStorage<string | null>(
    'lastLoginTimestamp',
    null
  );

  useEffectOnce(() => {
    const query = new URLSearchParams(location.search);

    // This isn't ideal - we're using a URL parameter to determine when to
    // show the toast to get a cleaner handling of an auto signout (you
    // can try and just cause the toast in useProvideAuth, but then the page
    // breaks after a second or two as all the GraphQL queries shit the bed
    // thinking).
    // So: if we see the query param indicating we've been signed out we replace
    // state without that param set and show the toast. This then means on the next
    // page refresh we don't get re-toasted, and we've gotten our nice clean
    // auth handling
    const hadSignedOutFlag = query.has('signedOut');
    const lastLoginMinutesAgo = lastLoginTimestampIsoString
      ? (Number(new Date()) - Number(new Date(lastLoginTimestampIsoString))) /
        (60 * 1000) // ms -> minutes
      : null;

    const isOnSignInPath = location.pathname?.startsWith('/sign-in');

    query.delete('signedOut');

    if (hadSignedOutFlag && !isOnSignInPath) {
      if (
        !signedIn &&
        (lastLoginMinutesAgo === null || lastLoginMinutesAgo < 120) /* 2 hrs */
      ) {
        // I mean this is pretty unambiguously a hack, isn't it? Attempting to
        // call openToast here causes a warning that we're updating parent state
        // from within the render of this child, so I guess by decoupling the
        // state change from this event loop pass' render we get away with it?
        setTimeout(() => {
          openToast(
            "It's been a while since your last activity, so we automatically signed you out.",
            ToastType.Information,
            5000
          );
        });
      }

      history.replace({
        search: query.toString(),
      });
    }
  });

  return null;
};

function App() {
  const { signedIn } = useAuth();

  const [initialSignedInValue] = useState<boolean>(signedIn);

  const [
    showOpenSippAsSecondAccountPage,
    setShowOpenSippAsSecondAccountPage,
  ] = useState<boolean | null>(null);

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
    onSuccess: (data) => {
      const userAccounts =
        data?.clientSummary?.accounts.map((account) => account.wrapperType) ??
        [];

      if (
        showOpenSippAsSecondAccountPage === null ||
        showOpenSippAsSecondAccountPage === false
      ) {
        setShowOpenSippAsSecondAccountPage(
          userAccounts?.length >= 1 && !userAccounts?.includes(WrapperType.Sipp)
        );
      }
    },
  });

  const [createSippEnabled, toggleQuery] = useToggle('global-create-sipps');

  const [xrayBetaEnabled] = useToggle('x-ray-beta');
  usePageViews();

  const handleCookiesAllowed = (preferences: CookiePreferences) => {
    if (preferences.allowPerformanceCookies) {
      mixpanel.set_config({
        disable_persistence: false,
      });

      if (!mixpanel.has_opted_in_tracking()) {
        mixpanel.opt_in_tracking();
      }
    }
    //setupGtm(preferences);
    setupGtag(preferences);
  };

  return (
    <>
      <SVGColorFilter />
      <LoadingScreen />
      <GlobalStyle />

      <QueryState
        {...userProfileQuery}
        suppressLoadingState={!initialSignedInValue} // Don't show loading state on the sign-in page
        dataTestid="userProfileQuery"
      >
        {() => (
          <QueryState {...toggleQuery} dataTestid="toggleQuery">
            {() => (
              <FundsBasketProvider>
                <SelectFundsPercentagesStepContextProvider>
                  <ModalProvider legacy>
                    <ToastProvider>
                      <ScrollToTop />
                      <SignedOutToaster />
                      <UrlTriggeredChatPopper />
                      <CookieConsent
                        onPreferencesChanged={handleCookiesAllowed}
                      />
                      <ResumeOnboardingNudge />
                      <Layout>
                        <Suspense fallback="Loading...">
                          <Switch>
                            <Route exact path={hexagonPagePath}>
                              <Landing />
                            </Route>
                            <Route path={appSignInSignUpPath} exact>
                              <SignInSignUp />
                            </Route>
                            <Route path={appSignInPath} exact>
                              <SignIn />
                            </Route>
                            <Route path={appResetPasswordPath} exact>
                              <ResetPassword />
                            </Route>
                            <Route path={fundListPath} exact>
                              <Funds />
                            </Route>
                            <Route path={fundDetailsOldPath} exact>
                              <RedirectFundDetails />
                            </Route>
                            <Route
                              path={[fundDetailsPath, fundDetailsSubPath]}
                              exact
                            >
                              <FundDetails />
                            </Route>

                            {createSippEnabled?.enabled &&
                              showOpenSippAsSecondAccountPage && (
                                <AuthRoute path={openPensionPath}>
                                  <OpenSippAsSecondAccount />
                                </AuthRoute>
                              )}

                            <AuthRoute
                              path={[
                                openAccountPath,
                                openAccountResumeTypePath,
                                openAccountTypePath,
                                openAccountResumeAccountPath,
                              ]}
                              alternative={<OpenAccountPreAuth />}
                              exact
                            >
                              <OpenAccount />
                            </AuthRoute>

                            <AuthRoute
                              path={[
                                dashboardPath,
                                dashboardSubPath,
                                dashboardAccountAddCashPath,
                              ]}
                              exact
                            >
                              <MyDashboard />
                            </AuthRoute>

                            <AuthRoute
                              path={invitationPath}
                              alternative={<UnAuthInvitationRedirect />}
                              exact
                            >
                              <AuthInvitationRedirect />
                            </AuthRoute>

                            <AuthRoute path="/account/:id/cash/deposit">
                              <CashDepositDeepLink />
                            </AuthRoute>

                            <AuthRoute path="/account/:id">
                              <Redirect to="/dashboard" />
                            </AuthRoute>

                            <AuthRoute path={startTransferPath}>
                              <StartTransferPage />
                            </AuthRoute>
                            <Route
                              path={[
                                dynamicPortfolioConstructionPath,
                                dynamicPortfolioConstructionFundsAddCashPath,
                                checkoutPath,
                                dynamicPortfolioConstructionBasePath,
                              ]}
                            >
                              <DynamicPortfolioConstructionPage />
                            </Route>
                            <AuthRoute path="/uploadrequests/:id">
                              <UploadDocumentToRequest />
                            </AuthRoute>

                            <AuthRoute path={autoSaveInvestPath}>
                              <AutoSaveInvestPage />
                            </AuthRoute>

                            {xrayBetaEnabled?.enabled && (
                              <AuthRoute path={xrayBetaPath}>
                                <XrayBetaPage />
                              </AuthRoute>
                            )}
                            <AuthRoute path="/setup-mfa">
                              <EnableMfa />
                            </AuthRoute>
                            <Route path="/signed-out">
                              <SignedOut />
                            </Route>
                            <Route>
                              <NotFound />
                            </Route>
                          </Switch>
                        </Suspense>
                      </Layout>
                    </ToastProvider>
                  </ModalProvider>
                </SelectFundsPercentagesStepContextProvider>
              </FundsBasketProvider>
            )}
          </QueryState>
        )}
      </QueryState>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
