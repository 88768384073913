import { Container, Tabs, Typography } from '@material-ui/core';
import { styledLinkStyleOnly } from 'components/design-system/Link/Link.styles';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ExtraSmallStraitNeumorphicShadow } from 'theme/shared/base.styles';
import { legacyBasePadding } from 'theme/shared/spacing.styles';

const mainViewBaseCSS = css`
  padding-bottom: 80px;
`;

export const Divider = styled.hr`
  margin-top: 20px;
  margin-bottom: 0;
  display: block;
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${colors.clouds};
`;

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
`;

interface FundPageNavProps {
  $isDarkUniverse: boolean;
}
export const FundPageNav = styled.div<FundPageNavProps>`
  position: sticky;
  width: 100%;
  top: 5rem;
  z-index: ${({ theme }) => theme.zIndex.other};
  display: flex;
  align-content: center;
  padding: 0 1rem;
  justify-content: center;
  gap: 1rem;
  background: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors['grey-50'] : colors['magenta-25']};
  flex-wrap: wrap;
  body.banner--open & {
    top: 8rem;
  }
`;

export const StyledTabs = styled(Tabs)`
  height: auto;
  .MuiTabs-indicator {
    background-color: transparent;
  }

  .MuiButtonBase-root {
    padding: 0.5rem 1rem;
    height: auto;
    width: auto;
    min-width: auto;
  }

  .MuiButtonBase-root {
    padding: 0;
    .MuiTab-wrapper {
      flex-grow: 1;
    }
  }
`;

interface StyledNavLinkProps {
  $isDarkUniverse: boolean;
}
export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors['grey-900'] : 'black'};
  text-decoration: none;
  padding: 0.875rem 0.5rem;
  text-transform: none;
  font-size: 1rem;
  font-weight: 400;
  a&,
  a&:visited,
  a&:hover,
  a&:focus {
    outline: none;
  }

  &.active {
    color: ${({ $isDarkUniverse }) =>
      $isDarkUniverse ? colors.black : colors['magenta']};
  }

  &:hover,
  &:focus {
    color: ${({ $isDarkUniverse }) =>
      $isDarkUniverse ? colors.black : colors['magenta']};
  }
`;

export const ContentContainer = styled(Container)`
  ${legacyBasePadding};
  margin: 0;

  ${(p) =>
    p.theme.darkUniverse &&
    `
    background-color: ${colors.white};
  `}
`;

export const PageContainerInner = styled(Container)`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const ContentContainerInner = styled(Container)`
  padding: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content min-content min-content min-content min-content;
  grid-template-areas:
    'risk'
    'view'
    'team'
    'meet'
    'performance'
    'breakdown'
    'sidebar'
    'actions';

  ${(p) =>
    p.theme.darkUniverse &&
    `
    grid-template-rows: min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
    "rationale"
    "view"
    "performance"
    "breakdown"
    "sidebar"
    "actions";
  `}

  /* ${(p) => p.theme.breakpoints.up('md')} {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: min-content min-content min-content min-content min-content min-content;
      gap: 40px 40px;
      grid-template-areas:
        "view sidebar"
        "team sidebar"
        "performance sidebar"
        "breakdown sidebar"
        "actions sidebar";

        ${(p) =>
    p.theme.darkUniverse &&
    `
          grid-template-rows: min-content min-content min-content min-content min-content;
          grid-template-areas:
            "view sidebar"
            "performance sidebar"
            "breakdown sidebar"
            "actions sidebar";
        `}
    } */

    &.sustainable {
    grid-template-areas:
      'risk'
      'view'
      'sustainability'
      'team'
      'meet'
      'performance'
      'breakdown'
      'sidebar'
      'actions';

    /* ${(p) => p.theme.breakpoints.up('md')} {
        grid-template-areas:
          "view sidebar"
          "sustainability sidebar"
          "team sidebar"
          "performance sidebar"
          "breakdown sidebar"
          "actions sidebar";
      } */
  }

  & > * {
    min-width: 0;
  }
`;

export const RationaleContainer = styled.div`
  grid-area: rationale;
  text-align: center;
  ${mainViewBaseCSS};
  padding-top: 50px;
  p {
    text-align: left;
    a {
      ${styledLinkStyleOnly};
    }
  }
`;

export const ViewContainer = styled.div`
  ${mainViewBaseCSS}
`;

export const SustainabilityContainer = styled.div`
  ${mainViewBaseCSS}
  box-shadow: -30px -30px 60px #FAFBFF, 0px 20px 30px rgba(166, 171, 189, 0.25);
  border: 1px solid ${transparentize(0.7, colors.magenta)};
  border-radius: 30px;
  margin: 40px 0 70px 0;
  padding: 15px 30px 40px 30px;
  position: relative;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin: 40px -40px 70px -40px;
    padding: 15px 40px 40px 40px;
  }
`;

export const TeamContainer = styled.div`
  grid-area: team;
  ${mainViewBaseCSS}
`;

export const MeetManagerContainer = styled.div`
  grid-area: meet;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mainViewBaseCSS}
`;

export const MeetManagerPlaceholder = styled.img`
  height: auto;
  width: 100%;
  margin-top: 1.25rem;
  border-radius: 20px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 832px;
  }
`;

export const PerformanceContainer = styled.div`
  grid-area: performance;
  ${mainViewBaseCSS}
`;

export const BreakdownContainer = styled.div`
  grid-area: breakdown;
  ${mainViewBaseCSS}
`;

export const InformationContainer = styled.div`
  ${mainViewBaseCSS}
`;

export const ActionsContainer = styled.div`
  grid-area: actions;
  ${mainViewBaseCSS}
`;

export const DetailsContainer = styled.div`
  grid-area: details;
  margin-bottom: 25px;
`;

export const DocumentsContainer = styled.div`
  grid-area: documents;
`;

export const NewTitle = styled(Typography).attrs({
  component: 'h2',
})`
  position: relative;
  color: ${colors.richBlack};
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  font-size: 1.75rem;
  padding: 1.5rem 0;
  margin-top: 0;
  font-weight: 300;

  &:before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 1.5px;
    background-color: ${() => transparentize(0.85, colors.richBlue)};
  }
`;

export const DocumentsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 1.5rem 2.5rem;
    grid-template-areas: '. .';
    padding: ${(p) => p.theme.spacing(1.25, 0, 7.5, 0)};
  }
`;

export const Title = styled(Typography).attrs({
  component: 'h1',
})`
  flex-grow: 1;
  margin-bottom: 13px;
  font-weight: 300;
  font-family: ${(p) => p.theme.typography.headingFontFamily};
  font-size: 2rem;
  color: ${colors.darkBlue};
  text-align: center;
  padding-top: 30px;
  contain: content;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 3.5rem;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${colors.clouds};

    ${(p) =>
      p.theme.darkUniverse &&
      `
      background-color: ${colors.lightGrey};
    `}
  }
`;

export const SectionIntro = styled(Typography)`
  font-size: 1rem;
  font-weight: 300;
  color: ${colors.richBlack};
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 800px;
  }
`;

export const SubTitle = styled.div`
  position: relative;
  padding-top: ${(p) => p.theme.spacing(3.75)}px;
  font-weight: 300;
  font-family: ${(p) => p.theme.typography.headingFontFamily};
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: ${colors.darkBlue};
  &:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 1px;
    bottom: -5px;
    background-color: ${colors.magenta};
    opacity: 0.3;
  }
`;

export const NoContent = styled(Typography)`
  font-weight: 300;
  text-align: center;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin: 2.5rem 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 70px 120px;
    margin: 3rem 0;
  }
`;

export const StyledContentSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 45px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1 / -1;
    gap: 70px 120px;
  }
`;

type spacerType = {
  size: number;
};

export const Spacer = styled.div<spacerType>`
  padding-top: ${(p) => p.size}px;
`;

const getVideoMaxWidth = (isLarge?: boolean, hasChapters?: boolean) => {
  if (hasChapters) return '100%';
  return isLarge ? '850px' : '720px';
};

export const VideoWrapper = styled.div.attrs(
  (props: { isLarge?: boolean; marginTop?: boolean; hasChapters?: boolean }) =>
    props
)`
  position: relative;
  width: 100%;
  max-width: 520px;
  margin-top: ${({ marginTop }) => (marginTop ? '10px' : '0')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${({ hasChapters }) => (hasChapters ? '100%' : '640px')};
    margin-top: ${({ marginTop }) => (marginTop ? '10px' : '0')};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: ${({ isLarge }) => (isLarge ? '100%' : '50%')};
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: ${({ isLarge, hasChapters }) =>
      getVideoMaxWidth(isLarge, hasChapters)};
    margin-top: ${({ marginTop }) => (marginTop ? '30px' : '0')};
  }
`;

interface OverlayProps {
  isLarge?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  width: 100%;
  top: 0;
  padding-top: 56.25%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  z-index: 1;
  button {
    border-radius: 5px;
    height: 38px;
    align-self: center;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 0;
    height: 100%;
    max-width: ${({ isLarge }) => (isLarge ? '100%' : '65%')};
    left: 0;
  }
`;

export const KeyValueContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${colors['grey-100']};
  border-radius: 10px;
  margin: 2rem auto;
`;

export const BreakdownAttributionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > div {
    width: 100%;
  }
`;

export const TargetDateFormWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const TargetDateFormInner = styled.div`
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  border: 1px solid ${colors['grey-300']};
  ${ExtraSmallStraitNeumorphicShadow};
`;
